<template>
  <div :class="isDetailModal ? 'bg-grey5' : 'bg-white'">
    <div class="d-flex card-header p-relative" style="background-image: url('/assets/img/berlin2.png')">
      <img src="/assets/img/logo/9.png" alt="" class="company-logo">
      <div class="img-desc">
        13-15/01/2021
      </div>
    </div>
    <div class="p-2 p-relative">
      <img src="/assets/img/icons/share-alt-light-black.svg" alt="" class="share-icon">
      <h4>PPAI Expo</h4>
      <p class="fs14 desc-block">
        Exhinition for advertising and Promotional Items
      </p>
      <p class="fs14">
        CICC Cairo International...
      </p>
      <p class="fs14">
        Cairo
      </p>
      <div class="d-flex justify-content-between mt-5">
        <country-flag country='fr' size='big'/>
        <div class="d-flex align-items-center pointer" @click="openDetail">
          <img src="/assets/img/icons/calendar-alt-light-blue.svg" alt="" class="mx-2">
          <p class="fs14 color-blue7">{{ $t("More dates") }}</p> 
        </div>
        <router-link :to="{ name: 'TradeShowsDetails' }" class="primary-btn primary-btn_sm d-flex info-btn align-items-center">
          <img src="/assets/img/icons/info-white.svg" alt="" class="m-rl-10">
          {{ $t("Info") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpcomingItem',
  props: {
    isDetailModal: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    openDetail() {
      this.$emit("openMoreDate");
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header {
  height: 180px;
  background-size: cover;
  .company-logo {
    position: absolute;
    left: 0;
    top: 0;
    height: 80px;
    width: 80px;
  }
  .img-desc {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
}

.desc-block {
  height: 5em;
}

.info-btn {
  width: auto;
  padding: 0 10px;
}

.share-icon {
  position: absolute;
  right: 5px;
  top: 3px;
}
.rtl-type {
  .share-icon {
    right: unset;
    left: 5px;
  }
}
</style>