<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <section class="tourism-container">
      <h4 class="text-center mt-15">{{ $t('Upcoming trade shows') }}</h4>
      <div class="d-flex mb-5">
        <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
        <FilterTourism></FilterTourism>
      </div>
      <div class="row">
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
        <div class="col-md-3">
          <UpcomingItem @openMoreDate="openTradeDetailModal"></UpcomingItem>
        </div>
      </div>
    </section>
    <TradeDetailModal></TradeDetailModal>
  </main>
</template>

<script>
import UpcomingItem from "../../../components/tourism/tradeShow/UpcomingItem.vue" 
import FilterTourism from "../../../components/tourism/FilterTourism";
import SortTourism from "../../../components/tourism/SortTourism";
import  TradeDetailModal from "../../../components/tourism/tradeShow/TradeDetailModal.vue";
export default {
  name: 'TradeShowsUpcoming',
  components: {
    UpcomingItem,
    FilterTourism,
    SortTourism,
    TradeDetailModal
  }, 
  methods: {
    sortDeals() {
    },
    openTradeDetailModal() {
      this.$modal.show("TradeDetailModal");
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";


</style>